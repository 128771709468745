








































import { Component, Vue } from 'vue-property-decorator';
import { AccountModule } from '@/store/index';
import AccountFilters from './AccountFilters/AccountFilters.vue';
import AccountModal from './AccountModal/AccountModal.vue';
import { AccountHeaders } from './models/AccountHeaders';
import TmsTable from '@/shared/components/table/TmsTable.vue';
import AccountModalQb from './AccountModalQb/AccountModalQb.vue';
import { ToastHelper } from '@/utils/toast.util';
import { PermissionsService } from '@/shared/services/permission/permission.service';

@Component({
  components: { AccountFilters, AccountModal, TmsTable, AccountModalQb }
})
export default class Account extends Vue {
  accountId = null;
  accountModule = AccountModule;
  counter = 0;
  disabled = false;
  headersList = AccountHeaders;
  loading = false;

  constructor() {
    super();
  }

  updateLoading(status) {
    this.loading = status;
  }

  get tableData() {
    return this.accountModule.accounts.map(account => ({
      ...account,
      ...{ dummyLabel: account.dummy ? '✓' : '' }
    }));
  }

  get isLastPage(): boolean {
    return !AccountModule.meta.lastKey;
  }

  created() {
    this.disabled = PermissionsService.can('MASTER', 'DRIVER', 'View');
  }

  private changeAccount(id) {
    this.$bvModal.show('account-modal');
    this.accountId = id;
  }

  private accountModalQB(id) {
    this.$bvModal.show('account-modal-qb');
    this.accountId = id;
  }

  onAction({ data }: { key: string; data: any }) {
    data.qbId ? this.accountModalQB(data.id) : this.changeAccount(data.id);
  }
}
