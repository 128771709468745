import { i18n } from '@/i18n';
import { TableHeaders } from '@/shared/components/table/models';

export const AccountHeaders: TableHeaders[] = [
  {
    key: 'type1Name',
    label: i18n.tc('order.containerType')
  },
  {
    key: 'id',
    label: i18n.tc('mainTable.acctCode')
  },
  {
    key: 'name',
    label: i18n.tc('mainTable.acctCodeName')
  }
];
